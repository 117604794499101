<template>
  <div>
    <div class="with-bread-crumbs">
      <span class="h1">{{ truncate(subjectTitle, 15) }}</span>
      <span class="sep">/</span>
      <a href="javascript:void(0)" @click="goBack">{{ directionTitle }}</a>
      <span class="sep">/</span>
      <router-link to="/programs">Регистрация на программы</router-link>
    </div>
    <div class="current-programs">
      <div v-for="program in programs" :key="program.id" class="program">
        <span class="name">{{ program.title }} ({{ program.subject.title }})</span>
        <div class="short-info">
          <div class="title-value"><span class="title">Класс: </span><span class="value">{{ program.classes }}</span>
          </div>
          <div class="title-value"><span class="title">Период: </span><span class="value">{{ program.period }}</span>
          </div>
          <div class="end-registration red">
            <span class="icon">!</span>Дата окончания регистрации: {{ formatDate(program.reg_stop) }}
          </div>
        </div>

        <div class="description toggle" v-if="program.annotation" :class="{ 'open': descriptionVisibility[program.id].annotation }">
          <a href="javascript:void(0)" class="name-line" @click="toggleVisibility(program.id, 'annotation')">
            <span class="title">Описание программы</span>
            <span class="icon" :class="{ 'icon-minus': descriptionVisibility[program.id].annotation, 'icon-plus': !descriptionVisibility[program.id].annotation }"></span>
          </a>
          <div class="desc" v-show="descriptionVisibility[program.id].annotation" v-html="program.annotation"></div>
        </div>

        <div class="description toggle" v-if="program.selection_criteria" :class="{ 'open': descriptionVisibility[program.id].selectionCriteria }">
          <a href="javascript:void(0)" class="name-line" @click="toggleVisibility(program.id, 'selectionCriteria')">
            <span class="title">Критерии отбора</span>
            <span class="icon" :class="{ 'icon-minus': descriptionVisibility[program.id].selectionCriteria, 'icon-plus': !descriptionVisibility[program.id].selectionCriteria }"></span>
          </a>
          <div class="desc program-desc" v-show="descriptionVisibility[program.id].selectionCriteria" v-html="program.selection_criteria"></div>
        </div>

        <button class="button" @click="register(program.id)">Записаться</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useProgramsStore } from '@/stores/programsStore';
import { useRegistrationStore } from '@/stores/registrationStore';

export default {
  name: 'ProgramsListPage',
  data() {
    return {
      programs: [],
      subjectTitle: '',
      directionTitle: '',
      descriptionVisibility: {},
    };
  },
  async mounted() {
    const programsStore = useProgramsStore();
    const subjectId = this.$route.query.subject_id;
    this.subjectTitle = this.$route.query.subjectTitle;
    await programsStore.fetchProgramsForSubject(subjectId);
    this.programs = programsStore.programsForSubject;
    this.directionTitle = this.$route.query.directionTitle;
    const visibility = {};
    this.programs.forEach(program => {
      visibility[program.id] = { annotation: false, selectionCriteria: false };
    });
    this.descriptionVisibility = {...visibility};
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    truncate(string, length) {
      return string.length > length ? string.substring(0, length) + '...' : string;
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    async register(programId) {
      const registrationStore = useRegistrationStore();
      const result = await registrationStore.checkRegistration(programId);
      if (!result.success) {
        alert(result.message);
      } else {
        this.$router.push({ name: 'RegistrationPage', params: { programId: result.programId } });
      }
    },
    toggleVisibility(programId, type) {
      if (type === 'annotation') {
        this.descriptionVisibility[programId].annotation = !this.descriptionVisibility[programId].annotation;
      } else if (type === 'selectionCriteria') {
        this.descriptionVisibility[programId].selectionCriteria = !this.descriptionVisibility[programId].selectionCriteria;
      }
    },
  },
};
</script>

<style>
.program-desc p a {
  word-wrap: break-word;
  word-break: break-all;
}

.program .button {
  border: none;
  cursor: pointer;
}
</style>

