<template>
  <div>
    <span class="h1">Мои <span class="red">заявки</span></span>
    <div v-if="registrations.length" class="request-list">
      <div v-for="registration in registrations" :key="registration.id" class="request-item">
        <div :class="`status ${getStatus(registration.status_code)}`">
          {{ registration.status }}
          <span class="yes"
                v-if="registration.status_code === 1 && registration.arrive_confirmation === 1">Я приеду</span>
          <span class="no"
                v-if="registration.status_code === 1 && registration.arrive_confirmation === 2">Я не приеду</span>
        </div>
        <span class="name">{{ registration.program_title }}</span>
        <span class="subject-classes">{{ registration.subject }}, {{ registration.classes }} класс</span>
        <span class="date">{{ registration.period }}</span>
        <a v-if="registration.link_info" :href="registration.link_info" class="link-with-icon dop-line">Дополнительная
          информация</a>
        <a v-if="registration.link_online_event" :href="registration.link_online_event" class="link-with-icon dop-line">Ссылка
          на мероприятие</a>
        <a v-if="registration.can_be_canceled && registration.status_code === 0" href="javascript:void(0)"
           @click="cancelRegistration(registration.id)" class="cancel">Отменить</a>
        <div class="buttons" v-if="registration.status_code === 1 && registration.arrive_confirmation === 0">
          <a href="javascript:void(0)" @click="confirmArrival(registration.id, 1)" class="yes">Я приеду</a>
          <a href="javascript:void(0)" @click="openModal(registration.id)" class="no">Я не приеду</a>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Нет доступных заявок.</p>
    </div>
    <div v-if="showModal" class="shadow">
      <div class="popup">
        <a class="popup-closer" @click="closeModal"><i class="icon-close"></i></a>
        <div class="popup-content">
          <span class="popup-title">Укажите, пожалуйста, причину отказа</span>
          <textarea v-model="declineReason"></textarea>
          <a href="javascript:void(0)" @click="submitDecline" class="button">Подтвердить отмену</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRegistrationsStore} from '@/stores/registrationsStore';

export default {
  name: 'MyRequestsPage',
  data() {
    return {
      showModal: false,
      declineReason: '',
      currentRegistrationId: null
    };
  },
  computed: {
    registrations() {
      const store = useRegistrationsStore();
      return store.registrations;
    },
  },
  methods: {
    getStatus(statusCode) {
      switch (statusCode) {
        case 1:
          return 'accepted';
        case 0:
          return 'pending';
        default:
          return 'rejected';
      }
    },
    openModal(id) {
      this.showModal = true;
      this.currentRegistrationId = id;
    },
    closeModal() {
      this.showModal = false;
      this.declineReason = '';
    },
    confirmArrival(id, willArrive) {
      if (willArrive) {
        this.processArrivalConfirmation(id, 1);
      } else {
        this.openModal(id);
      }
    },
    submitDecline() {
      if (this.declineReason.length > 0) {
        this.processArrivalConfirmation(this.currentRegistrationId, 2, this.declineReason);
        this.closeModal();
      } else {
        alert('Пожалуйста, укажите причину отказа.');
      }
    },
    processArrivalConfirmation(id, confirmation, reason = '') {
      const store = useRegistrationsStore();
      store.confirmArrival(id, confirmation, reason);
    },
    cancelRegistration(id) {
      const store = useRegistrationsStore();
      store.cancelRegistration(id);
    },
  },
  mounted() {
    const store = useRegistrationsStore();
    store.fetchRegistrations();
  },
};
</script>

<style scoped>
</style>
