<template v-if="isUserDataLoaded">

  <div v-if="showWindowOk" class="overlay">
      <div class="popup-send" >
        <h2 class="blue">Уведомление</h2>
        <p>Вам на почту отправлено письмо
          со ссылкой для подтверждения. Пройдите по данной ссылке, чтобы подтвердить Вашу почту.</p>
        <button class="button" @click="()=>{this.showWindowOk=!this.showWindowOk}">Понятно</button>
        <button class="exit-black" @click="()=>{this.showWindowOk=false}">
          <img src="@/assets/images/exit-black.svg" alt="exit">
        </button>
      </div>
  </div>
  <div>
      <span class="h1">Мои <span class="red">данные</span></span>

      <div v-if="userData" class="user-full-name">
        <h2>{{ userData.last_name }} {{ userData.first_name }} {{ userData.middle_name }}</h2>
      </div>

      <div v-if="isProfileComplete && !emailConfirmed" class="alert alert-warning">
        <strong>Внимание!</strong> Пожалуйста, подтвердите ваш email. Перейдите по ссылке в письме, которое мы отправили
        на вашу почту.
      </div>

      <div class="gus-stages">
        <div>
          <img v-if="userData && userData.badge_level > 2" src="@/assets/images/achive1.png" alt="achive1"/>
          <img v-else src="@/assets/images/achive1gs.png" alt="achive1gs"/>
        </div>
        <div>
          <img v-if="userData && userData.badge_level > 1" src="@/assets/images/achive2.png" alt="achive2"/>
          <img v-else src="@/assets/images/achive2gs.png" alt="achive2gs"/>
        </div>
        <div>
          <img v-if="userData && userData.badge_level > 0" src="@/assets/images/achive3.png" alt="achive3"/>
          <img v-else src="@/assets/images/achive3gs.png" alt="achive3gs"/>
        </div>
      </div>

      <p class="text-desc brown">
        Персональные данные получены из&nbsp;ФГИС "Моя школа". Чтобы их&nbsp;изменить
        обратитесь к&nbsp;администрации вашего учебного
        заведения. Здесь вы&nbsp;можете изменить только свой
        телефон, email и&nbsp;данные родителя (законного
        представителя).
      </p>

      <div v-if="!isProfileComplete" class="alert alert-info">
        <strong>Внимание!</strong> Пожалуйста, заполните все необходимые данные вашего профиля (почта, телефон, данные
        родителя и выбор предметов) для полного доступа к функциям личного кабинета.
      </div>

      <div class="personal-list" v-if="userData">

        <a href="javascript:void(0)" class="change-photo mypage-item" @click="clickFileInput">
          <input type="file" @change="handleFileUpload" accept="image/jpeg, image/png" class="file-input" ref="fileInput">
          <span class="icon-change-photo"></span>Изменить фотографию
        </a>

        <div class="mypage-item">
          <a href="javascript:void(0)" class="name-toggle">
            <span class="name">
              Персональные данные
            </span>
            <span class="icon-minus icon"></span>
            <span class="icon-plus icon"></span>
          </a>
          <div class="inputs-list">
            <div class="title-value">
              <span class="title">Пол: </span>
              <span class="value">{{ userData.gender }}</span>
            </div>
            <div class="title-value">
              <span class="title">Дата рождения: </span>
              <span class="value">{{ formatDate(userData.birth_date) }}</span>
            </div>
            <div class="title-value">
              <span class="title">Класс: </span>
              <span class="value">{{ userData.class }}</span>
            </div>
            <div class="title-value">
              <span class="title">Школа: </span>
              <span class="value">{{ userData.school }}</span>
            </div>
            <div class="title-value">
              <span class="title">Муниципалитет: </span>
              <span class="value">{{ userData.municipality }}</span>
            </div>
            <div class="inputs-list">
              <span class="title">Размер одежды</span>
              <div class="select" @click="toggleSizesDropdown">
                <span class="selected">
                  {{ userData.merch_size || 'Выберите размер одежды' }}
                </span>
                <div v-if="sizesDropdown" class="dropdown">
                  <div v-for="(size, index) in existMerchSises" :key="index" @click.stop="selectSize(size)">
                    {{ size }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mypage-item">
          <a href="javascript:void(0)" class="name-toggle">
            <span class="name">Выбор профильных предметов</span>
            <span class="icon-minus icon"></span>
            <span class="icon-plus icon"></span>
          </a>
          <div class="inputs-list">
            <div v-if="userData.can_select_subjects">
              <div v-for="(selectedSubject, index) in userData.selected_subjects" :key="`subject-${index}`"
                   class="select" @click="toggleSubjectDropdown(index)">
                <span class="selected">
                  {{ subjects.find(subject => subject.id === selectedSubject)?.title || 'Выберите предмет' }}
                </span>
                <div v-if="isDropdownOpen(index)" class="dropdown">
                  <div v-for="subject in subjects" :key="subject.id" @click.stop="selectSubject(subject.id, index)">
                    {{ subject.title }}
                  </div>
                </div>
              </div>
              <div @click="addNewSubject" class="add-subject-button" v-if="userData.selected_subjects.length < 2">
                Добавить предмет
              </div>
            </div>
            <div v-else>
              <div v-for="(subjectId, index) in userData.selected_subjects" :key="`subject-${index}`" class="select">
                <span class="selected">
                  {{ subjects.find(subject => subject.id === subjectId)?.title || 'Предмет не выбран' }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="mypage-item">
          <a href="javascript:void(0)" class="name-toggle">
            <span class="name">Контактные данные</span>
            <span class="icon-minus icon"></span>
            <span class="icon-plus icon"></span>
          </a>
          <div class="inputs-list">
            <div class="phone input-with-icon">
              <input type="text"
                     placeholder="Телефон"
                     v-model="userData.phone"
                     @input="formatPhoneNumber"
                     @focus="showHint = true"
                     @blur="showHint = false"
                     maxlength="11"/>
              <p v-if="showHint" class="input-hint">
                Номер телефона необходимо вводить строго в формате 79993332211
              </p>
            </div>
            <div class="email input-with-icon">
              <input type="text" placeholder="Email" v-model="userData.email"/>
              <div v-if="isProfileComplete && !emailConfirmed" class="question-img" >?</div>
            </div>
            <a v-if="isProfileComplete && !emailConfirmed" @click.prevent="sendEmail" href="#" class="confirm-link">Подтвердите почту</a>
          </div>
        </div>

        <div class="mypage-item">
          <a href="javascript:void(0)" class="name-toggle">
            <span class="name">Законный представитель</span>
            <span class="icon-minus icon"></span>
            <span class="icon-plus icon"></span>
          </a>
          <div class="inputs-list">
            <div class="surname input-with-icon">
              <input type="text" placeholder="Фамилия" v-model="userData.relative.last_name"/>
            </div>
            <div class="pname input-with-icon">
              <input type="text" placeholder="Имя" v-model="userData.relative.first_name"/>
            </div>
            <div class="psecondname input-with-icon">
              <input type="text" placeholder="Отчество" v-model="userData.relative.middle_name"/>
            </div>
            <div class="phone input-with-icon">
              <input type="text"
                     placeholder="Телефон"
                     v-model="userData.relative.phone"
                     @input="formatPhoneNumber"
                     @focus="showRelativeHint = true"
                     @blur="showRelativeHint = false"
                     maxlength="11"/>
              <p v-if="showRelativeHint" class="input-hint">
                Номер телефона необходимо вводить строго в формате 79993332211
              </p>
            </div>
          </div>
        </div>

        <button @click="saveUserData" class="button">Сохранить изменения</button>

        <button @click="logout" class="button logout-button">Выйти</button>

      </div>

      <div v-else>
        <p>Загрузка данных...</p>
      </div>
  </div>

</template>

<script>
import {useUserStore} from '@/stores/userStore';
import {useRegistrationStore} from '@/stores/registrationStore';
import axios from 'axios';

export default {
  name: 'MyDataPage',
  data() {
    return {
      openDropdownIndex: null,
      showHint: false,
      showRelativeHint: false,
      showWindowOk: false,
      existMerchSises:[ '2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL' ],
      sizesDropdown:false,
    };
  },
  computed: {
    userData() {
      const store = useUserStore();
      return store.userData;
    },
    emailConfirmed() {
      const store = useUserStore();
      return store.emailConfirmed;
    },
    apiBaseUrl() {
      const userStore = useUserStore();
      return userStore.apiBaseUrl;
    },
    subjects() {
      const userStore = useUserStore();
      return userStore.subjects;
    },
    isProfileComplete() {
      const store = useUserStore();
      return store.isProfileComplete;
    },
  },
  methods: {
    formatDate(dateString) {
      const [year, month, day] = dateString.split('-');
      return `${day}.${month}.${year}`;
    },
    formatPhoneNumber(event) {
      const input = event.target.value;
      let numbers = input.replace(/\D/g, '');
      numbers = numbers.substring(0, 11);

      if (!numbers.startsWith('7')) {
        numbers = '7' + numbers.substring(1);
      }

      event.target.value = numbers;
    },
    clickFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const userStore = useUserStore();
      if (file && file.size <= 10 * 1024 * 1024) {
        this.uploadAvatar(file, userStore.accessToken);
      } else {
        alert('Файл должен быть формата JPG или PNG и не больше 10 МБ.');
      }
    },
    toggleSubjectDropdown(index) {
      if (!this.userData.can_select_subjects) return;
      this.openDropdownIndex = this.openDropdownIndex === index ? null : index;
    },
    isDropdownOpen(index) {
      return this.openDropdownIndex === index;
    },
    toggleSizesDropdown(){
      this.sizesDropdown = !this.sizesDropdown;
    },
    selectSize(size) {
      this.userData.merch_size = size;
      this.sizesDropdown = false;
    },
    logout() {
      const userStore = useUserStore();
      userStore.logout();
      this.$router.push({path: '/login', query: {logout: '1'}});
    },
    selectSubject(subjectId, index) {
      if (!this.userData.can_select_subjects) return;
      if (this.userData.selected_subjects.includes(subjectId)) {
        this.userData.selected_subjects.splice(index, 1);
      } else {
        this.userData.selected_subjects[index] = subjectId;
      }
      this.openDropdownIndex = null;
    },
    addNewSubject() {
      this.userData.selected_subjects.push(null);
      this.toggleSubjectDropdown(this.userData.selected_subjects.length - 1);
    },
    async uploadAvatar(file, accessToken) {
      const userStore = useUserStore();
      const formData = new FormData();
      formData.append('photo', file);
      try {
        await axios.post(`${this.apiBaseUrl}users/upload-photo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
          }
        });
        userStore.fetchUserData();
        alert('Аватар успешно обновлен');
      } catch (error) {
        console.error('Ошибка при загрузке аватара:', error);
        alert('Ошибка при загрузке аватара. Пожалуйста, попробуйте снова.');
      }
    },
    async saveUserData() {
      const userStore = useUserStore();
      const registrationStore = useRegistrationStore();

      if (!this.userData.email || !this.userData.phone ||
          !this.userData.relative || !this.userData.relative.last_name ||
          !this.userData.relative.first_name || !this.userData.merch_size || !this.userData.relative.middle_name ||
          !this.userData.relative.phone || this.userData.selected_subjects.length !== 2) {
        alert('Пожалуйста, заполните все обязательные поля и выберите ровно два предмета.');
        return;
      }

      const payload = {
        email: this.userData.email,
        phone: this.userData.phone.replace(/[^\d]/g, ''),
        relative: {
          last_name: this.userData.relative.last_name,
          first_name: this.userData.relative.first_name,
          middle_name: this.userData.relative.middle_name || "",
          phone: this.userData.relative.phone.replace(/[^\d]/g, '')
        },
        selected_subjects: this.userData.selected_subjects,
        merch_size:this.userData.merch_size
      };

      try {
        await axios.put(`${this.apiBaseUrl}users/${this.userData.id}`, payload, {
          headers: {
            'Authorization': `Bearer ${userStore.accessToken}`
          }
        });
        alert('Данные успешно обновлены');
        await userStore.fetchUserData();

        const pendingProgramId = localStorage.getItem('pendingProgramId');
        if (pendingProgramId) {
          const result = await registrationStore.checkRegistration(pendingProgramId);
          if (result.success && result.programId) {
            localStorage.removeItem('pendingProgramId');
            this.$router.push({name: 'RegistrationPage', params: {programId: result.programId}});
          } else {
            alert(result.message || 'Ошибка при регистрации. Попробуйте ещё раз.');
            this.$router.push('/');
          }
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        console.error('Ошибка при обновлении данных пользователя:', error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message || 'Неизвестная ошибка при обновлении данных.';
          if (error.response.data.errors) {
            const fieldErrors = Object.values(error.response.data.errors)
                .map(e => e.join(' ')).join(' ');
            alert(`Ошибка: ${errorMessage} Детали: ${fieldErrors}`);
          } else {
            alert(`Ошибка: ${errorMessage}`);
          }
        } else {
          alert('Ошибка при обновлении данных. Пожалуйста, попробуйте снова.');
        }
      }
    },
    async sendEmail() {
      const userStore = useUserStore();
      try {
        const response = await axios.post(`${this.apiBaseUrl}users/resend-email-verification`,{} , {
          headers: {
            Authorization: `Bearer ${userStore.getAccessToken}`
          }
        });
        if (response.status === 200) {
          this.showWindowOk = true;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            console.error('Ошибка авторизации. Проверьте токен доступа.');
          } else if (error.response.status === 500) {
            console.error('Что-то пошло не так на сервере.');
          } else {
            console.error('Неизвестная ошибка:', error.response.status);
          }
        } else {
          console.error('Ошибка при отправке запроса:', error.message);
        }
      }
    },
    isUserDataLoaded() {
      const userStore = useUserStore();
      return userStore.isUserDataLoaded;
    }
  },
  mounted() {
    const userStore = useUserStore();
    if (!userStore.isUserDataLoaded) {
      userStore.fetchUserData();
    }
    userStore.fetchSubjects();
  },
};
</script>

<style scoped lang="scss">

.user-full-name h2 {
  margin: 20px 10px 20px 10px;
}

.file-input {
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

button {
  margin: 10px auto 10px;
  font-size: 16px;
  font-weight: bold;
  border-color: transparent;
}

.logout-button {
  background-color: red;
  color: white;
}

.inputs-list {
  cursor: pointer;
}

.mypage-item {
  .name {
    position: relative;

    &:after {
      content: '*';
      position: absolute;
      color: red;
      font-size: 12px;
      top: -9px;
      left: -11px;
    }
  }
}

.alert-warning,
.alert-info {
  margin-bottom: 10px;
  color: red;
}

.input-hint {
  color: #666;
  font-size: 12px;
}

.h1 {
  @media (max-width: 767.98px) {
    display: none;
  }
}
.email.input-with-icon{
  display: flex;
justify-content: center;
}
.question-img{
  width:30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  background-color: rgba(184, 9, 36, 1);
  text-align: center;
}
.confirm-link{
  color:rgba(184, 9, 36, 1);
  text-decoration: underline;
  text-align: end;
}
button:hover{
  transform: scale(1.13);
  cursor:pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
  .popup-send{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    max-width: 345px;
    gap: 10px;
    padding: 20px 15px;
    border-radius: 8px;
    background-color: #ffffff;
    text-align: left;
  }
  .exit-black{
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
  }
  .popup-send h2{
    font-family: 'Merriweather', serif;
    color: rgba(13, 45, 98, 1);
    font-size: 18px;
    font-weight: 700;
    line-height: 22.63px;
    margin: 0;
  }
.popup-send p{
    margin: 0;
  }

</style>


