<template>
  <div>
    <ProgramSchedule :programId="programId" />
  </div>
</template>

<script>
import ProgramSchedule from '@/components/ProgramSchedule.vue';

export default {
  name: 'ProgramSchedulePage',
  components: {
    ProgramSchedule,
  },
  computed: {
    programId() {
      return parseInt(this.$route.params.programId, 10);
    }
  }
}
</script>
