<template>
  <div>
    <RegistrationForPrograms />
  </div>
</template>

<script>
import RegistrationForPrograms from '@/components/RegistrationForPrograms.vue';

export default {
  name: 'ProgramsPage',
  components: {
    RegistrationForPrograms
  }
};
</script>

<style scoped>
</style>
