<template>
  <div>
    <div v-if="error">
      <p>Ошибка авторизации: {{ error }}</p>
    </div>
    <div v-else-if="loading">
      Обработка авторизации...
    </div>
    <div v-else>
      <p>Авторизация успешно выполнена. Перенаправление...</p>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/userStore';
import { useRegistrationStore } from '@/stores/registrationStore';
import { ref } from 'vue';

export default {
  name: 'MeshAuthHandler',
  setup() {
    const error = ref(null);
    const loading = ref(true);
    return { error, loading };
  },
  async mounted() {
    const code = this.$route.query.code;
    if (code) {
      const userStore = useUserStore();
      const registrationStore = useRegistrationStore();
      try {
        await userStore.login(code);
        this.loading = false;
        // console.log("Авторизация прошла успешно");

        const pendingProgramId = localStorage.getItem('pendingProgramId');
        if (pendingProgramId) {
          const result = await registrationStore.checkRegistration(pendingProgramId);
          if (result.success && result.programId) {
            this.$router.push({ name: 'RegistrationPage', params: { programId: result.programId } });
          } else {
            alert(result.message || 'Ошибка при регистрации. Попробуйте ещё раз.');
            this.$router.push('/');
          }
        } else {
          // setTimeout(() => this.$router.push('/'), 2000);
          this.$router.push('/');
        }
      } catch (err) {
        this.error = err.message || 'Не удалось обработать код авторизации';
        this.loading = false;
        console.error('Ошибка при попытке логина:', err);
        setTimeout(() => this.$router.push('/login'), 3000);
      }
    } else {
      this.error = 'Код авторизации не найден, возвращение на страницу логина';
      this.loading = false;
      // console.log('Перенаправление на /login, код не найден');
      setTimeout(() => this.$router.push('/login'), 3000);
    }
  }
}
</script>
