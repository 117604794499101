<template>
  <div>
    <div class="with-bread-crumbs">
      <span class="h1">Запись на <span class="red">программу</span></span>
      <span class="sep">/</span>
      <router-link to="/">Программы</router-link>
    </div>

    <h2 v-if="registrationData && registrationData.program">{{ registrationData.program.title }}</h2>

    <div class="groups">
      <div v-if="registrationData && registrationData.program" class="group">
        <div class="title-value">
          <span class="title">Класс: </span>
          <span class="value">{{ registrationData.program.classes }} класс</span>
        </div>
        <div class="title-value">
          <span class="title">Период: </span>
          <span class="value"> {{ registrationData.program.period }}</span>
        </div>
      </div>
    </div>

    <div class="day-line steps-line">
      <div class="num-line">
        <a v-for="(step, index) in steps"
           :key="index"
           href="javascript:void(0)"
           :class="{ ready: step < currentStep, current: step === currentStep }">
          <span class="num">{{ step }}</span>
          <div class="date-line-item">
            <span class="date-desc">Шаг {{ step }}.</span>
            <span class="date">{{ stepsTitles[step - 1] }}</span>
          </div>
        </a>
      </div>
    </div>

    <div class="horizontal-blocks">

      <div v-if="currentStep === 1" class="hb-block">
        <span class="h2 step-name blue">Шаг 1. Личные данные</span>
        <div class="groups step1">

          <div v-if="registrationData && registrationData.user" class="group">
            <div class="title-value">
              <span class="title">ФИО: </span>
              <span class="value">
                {{ registrationData.user.last_name }} {{ registrationData.user.first_name }} {{ registrationData.user.middle_name }}
              </span>
            </div>
            <div class="title-value">
              <span class="title">Пол: </span>
              <span class="value"> {{ registrationData.user.gender }}</span>
            </div>
            <div class="title-value">
              <span class="title">Дата рождения: </span>
              <span class="value"> {{ formattedBirthDate }}</span>
            </div>
            <div class="title-value">
              <span class="title">Класс: </span>
              <span class="value"> {{ registrationData.user.class }}</span>
            </div>
            <div class="title-value">
              <span class="title">Школа: </span>
              <span class="value"> {{ registrationData.user.school }}</span>
            </div>
            <div class="title-value">
              <span class="title">Муниципалитет: </span>
              <span class="value">
                {{ registrationData.user.municipality }}
              </span>
            </div>
            <div class="title-value">
              <span class="title">Телефон: </span>
              <span class="value"> {{ registrationData.user.phone }}</span>
            </div>
            <div class="title-value">
              <span class="title">Email: </span>
              <span class="value"> {{ registrationData.user.email }}</span>
            </div>
          </div>

          <div class="group">
            <span class="title-to-values">Законный представитель</span>
            <div class="title-value">
              <span class="title">ФИО: </span>
              <span class="value">
                {{ registrationData.user.relative.last_name }}
                {{ registrationData.user.relative.first_name }}
                {{ registrationData.user.relative.middle_name }}
              </span>
            </div>
            <div class="title-value">
              <span class="title">Телефон: </span>
              <span class="value">
                {{ registrationData.user.relative.phone }}
              </span>
            </div>
          </div>

          <div class="reg-buttons-line">
            <router-link to="/my-data" class="buttondop">Изменить</router-link>
            <button @click="confirmStep" class="button">Подтверждаю</button>
          </div>
        </div>
      </div>
      <!-- /.hb-block -->

      <div v-if="currentStep === 2" class="hb-block">
        <span class="h2 step-name blue">Шаг 2. Достижения</span>
        <div class="groups">

          <div class="group">
            <span class="title-to-input">В прошлом учебном году Вы обучались в другом регионе?</span>
            <div class="radio-line">
              <input type="radio" v-model="achievementsData.aregion" name="about-region" id="rfirst" :value="true">
              <label for="rfirst">Да</label>
              <input type="radio" v-model="achievementsData.aregion" name="about-region" id="rsecond" :value="false">
              <label for="rsecond">Нет</label>
            </div>
          </div>

          <div class="group">
            <span class="title-to-input">Краткая информация о достижениях в профильном направлении?</span>
            <textarea v-model="achievementsData.info" name="about-achievements"></textarea>
          </div>

          <div v-for="(field, index) in sortedFields" :key="index" class="group">
            <div v-if="field?.type === 'text_area'">
              <span class="title-to-input">{{ field.title }}</span>
              <textarea v-model="achievementsData[field.name]" :name="field.name"></textarea>
            </div>

            <div v-if="field?.type === 'file'">
              <span class="title-to-input">{{ field.title }}</span>
              <span class="brown" v-html="field.description"></span>
              <div class="upload-list">
                <div v-for="(file, fileIndex) in achievementsData[field.name]" :key="fileIndex" class="upload-item">
                  <span class="num">{{ fileIndex + 1 }}.</span>
                  <span class="name">{{ file.name }}</span>
                  <div class="dotted-line">&nbsp;</div>
                  <a href="javascript:void(0)" @click.prevent="removeFile(field.name, fileIndex)">
                    <span class="icon-delete"></span>
                  </a>
                </div>
                <div class="attach">
                  <input type="file" :id="'file-upload-' + field.name" :name="field.name"
                         @change="handleFileChange($event, field.name)" multiple style="display:none;">
                  <label :for="'file-upload-' + field.name">
                    <span class="icon-attach"></span>
                    <span>Прикрепить файлы</span>
                  </label>
                </div>
              </div>
            </div>

            <div v-else-if="field?.type === 'list'">
              <span class="title-to-input">{{ field.title }}</span>
              <div v-for="(option, optionIndex) in field.options" :key="`option-${index}-${optionIndex}`">
                <input type="radio" :id="`option-${index}-${optionIndex}`" :value="option"
                       v-model="achievementsData[field.name]" :name="field.name">
                <label :for="`option-${index}-${optionIndex}`">{{ option }}</label>
              </div>
            </div>

            <div v-else-if="field?.type === 'checkbox'">
              <span class="title-to-input">{{ field.title }}</span>
              <div v-for="(option, optionIndex) in field.options" :key="`option-${index}-${optionIndex}`">
                <input type="checkbox" :value="option" v-model="achievementsData[field.name]"
                       :id="`checkbox-${index}-${optionIndex}`">
                <label :for="`checkbox-${index}-${optionIndex}`">{{ option }}</label>
              </div>
            </div>

            <div v-else-if="field?.type === 'text'">
              <span class="title-to-input">{{ field.title }}</span>
              <input type="text" v-model="achievementsData[field.name]" :name="field.name" class="styled-textarea">
            </div>
          </div>

          <div class="reg-buttons-line">
            <button @click="prevStep" class="buttondop">Назад</button>
            <button @click="nextStep" class="button">Далее</button>
          </div>

        </div>
        <!-- /.groups -->
      </div>
      <!-- /.hb-block -->

      <div v-if="currentStep === 3" class="hb-block">
        <span class="h2 step-name blue">Шаг 3. Подтверждения</span>
        <div class="groups">
          <div class="group">
            <input type="checkbox" id="confirmation-1" v-model="achievementsData.confirmation1">
            <label for="confirmation-1">
              Подтверждаю, что являюсь обучающимся образовательной организации Московской
              области
            </label>
            <input type="checkbox" id="confirmation-2" v-model="achievementsData.confirmation2">
            <label for="confirmation-2">
              Принимаю условия пользовательского соглашения и даю согласие на обработку
              персональных данных в соответствии с требованиями 152-ФЗ
            </label>
          </div>
          <div class="reg-buttons-line">
            <button @click="prevStep" class="buttondop">Назад</button>
            <button @click="submitForm" class="button">Записаться</button>
          </div>
        </div>
      </div>
      <!-- /.hb-block -->
      <div v-if="currentStep === 4" class="hb-block">
        <span class="h2 step-name blue">Загрузка данных...</span>
      </div>
      <div v-if="currentStep === 5">
        <div v-if="registrationData?.program" class="hb-block">
          <span class="h2 step-name blue">Поздравляем!</span>
          <div class="groups">
            <div class="group">
              Вы успешно записались на программу «{{ registrationData.program.program_title }}».
            </div>
            <div class="reg-buttons-line">
              <router-link to="/my-requests" class="button">Мои записи</router-link>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {useUserStore} from '@/stores/userStore';
import {useRegistrationStore} from '@/stores/registrationStore';
import axios from 'axios';

export default {
  name: 'RegistrationPage',
  props: ['programId'],
  data() {
    return {
      achievementsData: {
        aregion: null,
        info: '',
        confirmation1: false,
        confirmation2: false,
      },
      currentStep: 1,
      steps: [1, 2, 3, 4],
      stepsTitles: ['Личные данные', 'Достижения', 'Подтверждения', 'Завершение'],
    };
  },
  computed: {
    registrationData() {
      const registrationStore = useRegistrationStore();
      return registrationStore.registrationData;
    },
    sortedFields() {
      const order = ['list', 'text_area', 'checkbox', 'text', 'file'];
      if (!this.registrationData.additional_fields) return [];

      return [...this.registrationData.additional_fields].sort((a, b) => {
        return order.indexOf(a.type) - order.indexOf(b.type);
      });
    },
    userStore() {
      return useUserStore();
    },
    user() {
      return this.userStore.userData || {};
    },
    formattedBirthDate() {
      const dateParts = this.registrationData.user.birth_date.split('-');
      return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
    }
  },
  methods: {
    handleFileChange(event, fieldName) {
      const newFiles = Array.from(event.target.files).map(file => {
        const uniqueSuffix = Date.now() + '-' + Math.round(Math.random() * 1E9);
        const uniqueName = uniqueSuffix + '-' + file.name;
        const newFile = new File([file], uniqueName, {type: file.type});
        return newFile;
      });

      if (!this.achievementsData[fieldName]) {
        this.achievementsData[fieldName] = newFiles;
      } else {
        this.achievementsData[fieldName].push(...newFiles);
      }
    },
    confirmStep() {
      this.nextStep();

      if (this.currentStep === 2) {
        localStorage.removeItem('pendingProgramId');
      }
    },
    validateForm() {
      const errors = [];
      if (this.currentStep === 2 && (!this.achievementsData.info || this.achievementsData.aregion === null)) {
        errors.push("На этапе 'Достижения' все поля должны быть заполнены.");
      }
      if (this.currentStep === 3 && (!this.achievementsData.confirmation1 || !this.achievementsData.confirmation2)) {
        errors.push("На этапе 'Подтверждения' необходимо подтвердить все условия.");
      }

      if (errors.length > 0) {
        alert(errors.join("\n"));
        // console.log('Ошибки:', errors);
        return false;
      }
      // console.log('Валидация пройдена успешно');
      return true;
    },
    scrollToActiveStep() {
      this.$nextTick(() => {
        const activeElement = this.$el.querySelector('.current');
        if (activeElement) {
          activeElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
        }
      });
    },
    removeFile(fieldName, fileIndex) {
      this.achievementsData[fieldName].splice(fileIndex, 1);
    },
    nextStep() {
      // console.log(`Попытка перейти с шага ${this.currentStep} на шаг ${this.currentStep + 1}`);
      if (this.validateForm()) {
        const registrationStore = useRegistrationStore();
        registrationStore.setAchievementsData(this.achievementsData);
        this.currentStep++;
        this.scrollToActiveStep();
        // console.log(`Переход на шаг ${this.currentStep}`);
      } else {
        // console.log('Валидация не пройдена, остаемся на текущем шаге');
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.scrollToActiveStep();
      }
    },
    setStep(step) {
      if (step < this.currentStep) {
        this.currentStep = step;
        this.scrollToActiveStep();
      }
    },
    async submitForm() {
      if (!this.validateForm()) {
        // console.log('Форма не прошла валидацию');
        return;
      }
      const formData = new FormData();
      formData.append('program_id', this.programId);
      formData.append('aregion', this.achievementsData.aregion ? '1' : '0');
      formData.append('info', this.achievementsData.info);
      formData.append('confirmation', this.achievementsData.confirmation1 && this.achievementsData.confirmation2 ? '1' : '0');
      formData.append('moscow_region', '1');
      let globalFileIndex = 0;

      this.sortedFields.forEach(field => {
        if (field.type === 'file' && this.achievementsData[field.name] && this.achievementsData[field.name].length > 0) {
          this.achievementsData[field.name].forEach(file => {
            formData.append(`docs[${globalFileIndex}]`, file);
            globalFileIndex++;
          });
        } else if (this.achievementsData[field.name] !== undefined) {
          formData.append(field.name, this.achievementsData[field.name]);
        }
      });
      try {
        this.nextStep();
        const response = await axios.post(`${this.userStore.apiBaseUrl}registrations`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.userStore.accessToken}`
          }
        });
        if (response.status === 200 || response.status === 201 ) {
          this.showInfoLoading=false;
          this.registrationData.program = response.data.data;
          this.nextStep();
        } else {
          console.error('Ошибка при отправке формы:', response);
          alert('Ошибка при отправке формы: ' + (response.data.errors ? response.data.errors.join('; ') : response.statusText));
        }
      } catch (error) {
        console.error('Ошибка при отправке формы:', error);
        alert('Ошибка при отправке формы: ' + (error.response && error.response.data && error.response.data.errors ? Object.values(error.response.data.errors).join('; ') : error.message));
      }
    }

  },
  mounted() {
    const registrationStore = useRegistrationStore();
    if (!registrationStore.registrationData) {
      this.$router.push('/programs');
    } else {
      if (!this.userStore.userData) {
        this.$router.push('/login');
      } else {
        this.registrationData.additional_fields.forEach(field => {
          if ((field.type === 'checkbox' || field.type === 'file') && !(field.name in this.achievementsData)) {
            this.achievementsData[field.name] = [];
          }
        });
      }
    }
  },
};
</script>

<style scoped lang="scss">

.horizontal-blocks {
  overflow: visible;
}

.reg-buttons-line .button {
  border: none;
}

.styled-textarea {
  min-height: 100px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 4px;
  resize: none;
}

.upload-list span {
  cursor: pointer;
}

.buttondop {
  background-color: inherit;
}

.button,
.buttondop {
  font: var(--p);

  @media (max-width: 1199.98px) {
    padding: 10px 20px;
  }
}

</style>


