<template>

  <div class="coins-div fs">

    <router-link to="/coins-history"
                 class="coins-image">
      <img src="@/assets/images/coin.png">
      <span class="num">{{ balance }}</span>
    </router-link>

    <span>Vzlet Coins</span>

    <router-link to="/coins-info"
                 class="question">
      ?
    </router-link>

  </div>

  <div class="personal-menu">

    <router-link to="/" class="menu-item edit">
      <span class="icon icon-edit"></span>
      <span class="name">Программы</span>
      <div class="count">{{ programsCount }}</div>
    </router-link>

    <router-link to="/my-requests" class="menu-item requests">
      <span class="icon icon-requests"></span>
      <span class="name">Заявки</span>
      <div class="count">{{ registrationsCount }}</div>
    </router-link>

    <router-link to="/certificates" class="menu-item certificates">
      <span class="icon icon-certificates"></span>
      <span class="name">Сертификаты</span>
      <div class="count">{{ certificatesCount }}</div>
    </router-link>

    <!--    <router-link to="/" class="menu-item notifications">-->
    <!--      <span class="icon icon-notifications"></span>-->
    <!--      <span class="name">Уведомления</span>-->
    <!--      <div class="count">1</div>-->
    <!--    </router-link>-->

  </div>
  <!-- /.personal-menu -->

</template>

<script>
import { defineComponent, computed, watch, onMounted } from 'vue';
import { useCertificatesStore } from '@/stores/certificatesStore';
import { useRegistrationsStore } from '@/stores/registrationsStore';
import { useProgramsStore } from '@/stores/programsStore';
import { useCoinsStore } from '@/stores/coinsStore';
import { useUserStore } from '@/stores/userStore';

export default defineComponent({
  name: 'AppPersonalMenu',
  setup() {
    const userStore = useUserStore();
    const certificatesStore = useCertificatesStore();
    const registrationsStore = useRegistrationsStore();
    const programsStore = useProgramsStore();
    const coinsStore = useCoinsStore();

    const loadData = () => {
      if (userStore.accessToken) {
        certificatesStore.fetchCertificates();
        registrationsStore.fetchRegistrations();
        programsStore.fetchPrograms();
        coinsStore.fetchBalance();
      }
    };

    watch(() => userStore.accessToken, loadData);
    onMounted(loadData);

    const certificatesCount = computed(() => {
      return certificatesStore.certificates ? certificatesStore.certificates.length : 0;
    });
    const registrationsCount = computed(() => {
      return registrationsStore.registrations ? registrationsStore.registrations.length : 0;
    });
    const programsCount = computed(() => {
      return programsStore.programs ? programsStore.programs.length : 0;
    });
    const balance = computed(() => coinsStore.balance || 0);

    return {
      certificatesCount,
      registrationsCount,
      programsCount,
      balance
    };
  }
});
</script>

<style scoped lang="scss">

.personal-menu {
  @media (max-width: 575.98px) {
    padding-top: 20px;
  }
}

</style>
