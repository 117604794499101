<template>
  <div>
    <span class="h1">Сертификаты</span>
    <div class="sertificate-desc">
      <div class="text">
        Сертификат получат ученики, у которых есть зачёт по всем модулям курса. Решите верно все упражнения, чтобы получить
        сертификат с&nbsp;отличием. Ваш сертификат всегда будет храниться на странице «Сертификаты». Вы можете скачать
        его, распечатать или отправить ссылку на электронную версию.
      </div>
      <img src="@/assets/images/VzletSertificate.png"/>
    </div>
    <div class="sertificates-list">
      <div v-for="certificate in certificates" :key="certificate.id" class="sertificate-item">
        <div class="info">
          <span class="name">{{ certificate.program_title }}</span>
          <span class="num">{{ certificate.reg_number }}</span>
          <span class="date">{{ certificate.period }}</span>
        </div>
        <a :href="`javascript:void(0);`" @click="downloadCertificate(certificate.id)" class="download">
          <img src="@/assets/images/pdf.svg"/>
          <span>Скачать</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {useCertificatesStore} from '@/stores/certificatesStore';

export default {
  name: 'CertificatesPage',
  computed: {
    certificates() {
      const store = useCertificatesStore();
      return store.certificates;
    },
  },
  methods: {
    downloadCertificate(id) {
      const store = useCertificatesStore();
      store.downloadCertificate(id);
    }
  },
  mounted() {
    const store = useCertificatesStore();
    store.fetchCertificates();
  },
};
</script>

<style scoped>
</style>
