<template>
  <div>
    <div class="with-bread-crumbs">
      <span class="h1">История <span class="red">начислений</span></span>
      <span class="sep">/</span>
      <router-link to="/my-data">Мои данные</router-link>
    </div>
    <div class="link-to-store__wrap">
      <router-link to="/rewards-shop" class="button link-to-store">
        VZLËT SHOP
      </router-link>
    </div>
    <div v-if="transactions.length > 0" class="coins-history-list">
      <div v-for="transaction in transactions" :key="transaction.id" class="ch-item">
        <span class="date">{{ formatDate(transaction.created_at) }}</span>
        <div class="ch-item-line">
          <span class="name">{{ transaction.comment }}</span>
          <span class="num">{{ transaction.amount > 0 ? '+' : '' }}{{ transaction.amount }}</span>
          <img src="@/assets/images/coin.png">
        </div>
      </div>
    </div>
    <div v-else class="no-coins-message">
      <p>На данный момент у вас нет истории начислений.</p>
    </div>
  </div>
</template>

<script>
import {useCoinsStore} from '@/stores/coinsStore';

export default {
  name: 'CoinsHistoryPage',
  computed: {
    transactions() {
      const store = useCoinsStore();
      return store.transactions;
    },
  },
  methods: {
    formatDate(dateString) {
      const options = {year: 'numeric', month: 'long', day: 'numeric'};
      return new Date(dateString).toLocaleDateString('ru-RU', options);
    },
  },
  mounted() {
    const store = useCoinsStore();
    store.fetchTransactions();
  },
};
</script>

<style scoped>
.no-coins-message p {
  text-align: center;
  margin-top: 20px;
}

.link-to-store__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.link-to-store {
  max-width: 400px;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}
</style>
