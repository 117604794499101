<template>
  <div v-if="programs && programs.length > 0">
    <span class="h1"><span class="red">Программы.</span> Расписание</span>
    <div class="current-programs onmain">
      <div v-for="program in programs" :key="program.id" class="program">
        <router-link :to="`/schedule/${program.id}`" class="name-with-arrow">
          <span class="name">{{ program.title }}</span>
          <span class="icon-arrow-right"></span>
        </router-link>
        <span class="subject-classes">{{ program.subject.title }}, {{ program.classes }} класс</span>
        <span class="date">{{ program.period }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useProgramsStore } from '@/stores/programsStore';

export default {
  name: "ProgramsSchedule",
  computed: {
    programs() {
      const store = useProgramsStore();
      return store.programs;
    },
  },
  mounted() {
    const store = useProgramsStore();
    store.fetchPrograms();
  },
}
</script>
