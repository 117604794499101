import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { useRegistrationStore } from '@/stores/registrationStore';
import LoginPage from '../views/LoginPage.vue';
import MeshAuthHandler from '../views/MeshAuthHandler.vue';
import SPAuthHandler from '../views/SPAuthHandler.vue';
import FakeAuthPage from '../views/FakeAuthPage.vue';
import HomePage from '../views/HomePage.vue';
import MyDataPage from '../views/MyDataPage.vue';
import MyRequestsPage from '../views/MyRequestsPage.vue';
import CertificatesPage from '../views/CertificatesPage.vue';
import CoinsHistoryPage from '../views/CoinsHistoryPage.vue';
import CoinsInfoPage from '../views/CoinsInfoPage.vue';
import ProgramsPage from '../views/ProgramsPage.vue';
import ProgramSchedulePage from '../views/ProgramSchedulePage.vue';
import SubjectsPage from '../views/SubjectsPage.vue';
import ProgramsListPage from '../views/ProgramsListPage.vue';
import RegistrationPage from '../views/RegistrationPage.vue';
import RewardsShop from '../views/RewardsShop.vue';
import ErrorPage from '../views/ErrorPage.vue';


const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: { layout: 'empty' }
    },
    {
        path: '/auth/mesh',
        name: 'MeshAuthHandler',
        component: MeshAuthHandler
    },
    {
        path: '/auth/sp',
        name: 'SPAuthHandler',
        component: SPAuthHandler
    },
    {
        path: '/fake-dev-authorization',
        name: 'FakeAuth',
        component: FakeAuthPage,
        meta: { layout: 'empty' }
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: (to, from, next) => {
            const userStore = useUserStore();
            userStore.logout();
            next({ path: '/login', query: { logout: '1' } });
        },
    },
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/my-data',
        name: 'MyData',
        component: MyDataPage
    },
    {
        path: '/my-requests',
        name: 'MyRequests',
        component: MyRequestsPage
    },
    {
        path: '/certificates',
        name: 'Certificates',
        component: CertificatesPage
    },
    {
        path: '/coins-history',
        name: 'CoinsHistory',
        component: CoinsHistoryPage
    },
    {
        path: '/coins-info',
        name: 'CoinsInfo',
        component: CoinsInfoPage
    },
    {
        path: '/programs',
        name: 'Programs',
        component: ProgramsPage
    },
    {
        path: '/schedule/:programId',
        name: 'ProgramSchedule',
        component: ProgramSchedulePage,
        props: true
    },
    {
        path: '/programs/subjects/:directionId',
        name: 'Subjects',
        component: SubjectsPage,
        props: true
    },
    {
        path: '/programs/list',
        name: 'ProgramsList',
        component: ProgramsListPage,
        props: true
    },
    {
        path: '/registration/:programId',
        name: 'RegistrationPage',
        component: RegistrationPage,
        props: true
    },
    {
        path: '/rewards-shop',
        name: 'RewardsShop',
        component: RewardsShop,
    },
    {
        path: '/registrations/create',
        name: 'RegistrationCreate',
        beforeEnter: async (to, from, next) => {
            const userStore = useUserStore();
            const registrationStore = useRegistrationStore();

            if (!userStore.accessToken) {
                localStorage.setItem('pendingProgramId', to.query.program_id);
                next({ path: '/login' });
            } else {
                try {
                    const result = await registrationStore.checkRegistration(to.query.program_id);
                    if (result.success && result.programId) {
                        next({ name: 'RegistrationPage', params: { programId: result.programId } });
                    } else {
                        alert(result.message || 'Ошибка при регистрации. Попробуйте ещё раз.');
                        next({ path: '/' });
                    }
                } catch (error) {
                    console.error('Произошла ошибка при проверке регистрации:', error);
                    alert('Произошла внутренняя ошибка. Попробуйте ещё раз.');
                    next({ path: '/' });
                }
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: '/'
    },
    {
        path: '/error',
        name:'Error',
        component: ErrorPage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();

    if (!userStore.userData && userStore.accessToken) {
        await userStore.fetchUserData();
    }

    const profileComplete = userStore.isProfileComplete;
    const emailConfirmed = userStore.emailConfirmed;

    const publicPages = ['/login', '/fake-dev-authorization', '/auth/mesh', '/auth/sp'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = !!userStore.accessToken;
    if (userStore.isFetchingUserData) {
        // Если данные пользователя еще загружаются, подождите их загрузки
        next(false); // Останавливаем переход
        const unwatch = userStore.$subscribe(() => {
            if (!userStore.isFetchingUserData) {
                unwatch(); // Отписываемся от наблюдения после завершения загрузки
                router.push(to); // Повторяем переход после завершения загрузки данных
            }
        })
    }else if (loggedIn) {
        if (publicPages.includes(to.path)) {
            next({ path: '/' });
        } else if (!profileComplete && to.path !== '/my-data') {
            alert('Пожалуйста, заполните все обязательные поля вашего профиля.');
            next({ path: '/my-data' });
        }
        else if(!emailConfirmed && to.path !== '/my-data' ){
            // alert('Пожалуйста, подтвердите почту. Пройдите по ссылке в письме.');
            next({ path: '/my-data' });
        } else {
            next();
        }
    } else if (!loggedIn && authRequired) {
        next({ path: '/login' });
    } else {
        next();
    }
});

export default router;




