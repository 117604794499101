<template>
  <div>
    <span class="h1">VZLËT <span class="red">SHOP</span></span>
    <div class="shop-list">
      <div
          v-for="product in sortedProducts"
          :key="product.id"
          class="shop-item"
          :class="{ 'non-act': !product.available }"
          @click="selectProduct(product)">
        <img :src="product.photo || '@/assets/images/default.png'"/>
        <span class="name">{{ product.title }}</span>
        <div class="coins">
          <span>{{ product.price }}</span>
          <img src="@/assets/images/coin.png"/>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModel">&times;</span>
        <p>{{ selectedProduct.available ? meta.info_available : meta.info_not_available }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {useProductsStore} from '@/stores/productsStore';

export default {
  name: 'RewardsShop',
  data() {
    return {
      showModal: false,
      selectedProduct: null
    };
  },
  computed: {
    products() {
      const store = useProductsStore();
      return store.products;
    },
    sortedProducts() {
      return this.products.slice().sort((a, b) => b.available - a.available);
    },
    meta() {
      const store = useProductsStore();
      return store.meta;
    }
  },
  methods: {
    selectProduct(product) {
      this.selectedProduct = product;
      this.showModal = true;
    },
    closeModel() {
      this.showModal = false;
      this.selectedProduct = null;
    }
  },
  mounted() {
    const store = useProductsStore();
    store.fetchProducts();
  }
};
</script>

<style scoped lang="scss">
.shop-item {
  cursor: pointer;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>


